import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/templates/single-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Co-op parents contribute in two main ways.  First, parents take turns assisting in their child’s classroom, an activity we call ‘co-oping.’  Parents co-op on a rotating basis, frequency varies depending on the class size.  In general, the co-oping parent plays, reads, and helps with activities during class time, provides a healthy snack for the entire group, assists with clean-up and most of all, enjoys quality time spent with his or her child. `}</p>
    <p>{`Second, each family also holds a family job.  Jobs include a variety of administrative, community-building, maintenance and fundraising tasks, all of which help to run our school and make it a fun, well-informed and functional community. With the variety of choices, there is a job that meets every family's time and schedule preferences. Besides completing an individual family job, every family also participates in the school’s year-end cleanup.`}</p>
    <hr></hr>
    <h2>{`Perks for Parents`}</h2>
    <p>{`Along with being able to be part of your child’s first school experience and watch first-hand all of the amazing things that happen in our classrooms, getting involved in our school gives parents a chance to meet and work with other parents.  While our children are forming bonds with each other over the sandbox and the water table, our parents also have lots of opportunities to get to know each other.  During the course of our school year – at our special events, at drop-offs and pick-ups or through our family jobs – Co-op parents also form friendships.`}</p>
    <p>{`To those unfamiliar, it may seem hard to believe, but the ‘work’ of the Co-op often winds up being one of its most gratifying elements because our families are all working together.  The result:  Co-op parents, as much as Co-op kids, wind up developing enduring and even lifelong friendships. `}</p>
    <hr></hr>
    <h2>{`Responsibilities`}</h2>
    <ul>
      <li parentName="ul">{`During Outdoor Play, wash hands, prepare snack, fill water pitchers, vacuum office rug`}</li>
      <li parentName="ul">{`Join class for Outdoor Play, if possible, or join us for Morning Meeting`}</li>
      <li parentName="ul">{`Help children settle during Morning Meeting or assist teachers with getting children to the Potty`}</li>
      <li parentName="ul">{`Direct Snack Helpers to distribute Snack; sit with and join children in conversation; refill water pitchers as necessary`}</li>
      <li parentName="ul">{`Assist teachers with Snack Clean Up, wiping tables and chairs, and then assist with Journals`}</li>
      <li parentName="ul">{`Circulate during Free Play; join in the play or utilize questions posted throughout the room`}</li>
      <li parentName="ul">{`Direct children as they Clean Up after Free Play`}</li>
      <li parentName="ul">{`Assist with Setting up Lunch or washing hands`}</li>
      <li parentName="ul">{`Sit at lunch table, assist children with opening of lunches`}</li>
      <li parentName="ul">{`As children finish lunch, assist teachers with sweeping and cleaning tables and chairs`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      